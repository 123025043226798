import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Christmas from "./pages/Christmas";

function App() {
  return (
    <>
      <Router>
        <Routes>
        <Route path="/" exact element={<Christmas />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
