function Rutger() {
  return (
    <>
      <h2>Rutger</h2>
      <ul>
        <li>Carcasonne (brettspill)</li>
        <li>Gloomhaven (brettspill)</li>
        <li>Tøfler fra Shepherd eller lignende, størrelse 45 (f.eks. disse eller disse )</li>
        <li>Fancy rom (ikkje Neisson, L.R.Seale's Finest, Angostura 7 years, eller Plantation Grande Reserve. Har smakt, ikkje mine favoritter) Søt og rund i smaken er best. Noen som eg vet eg liker: Zaccapa 23, Zaccapa XO, Diplomatico R.E. 12 añejo, Santa Teresa 1796.</li>
        <li>Cocktailpinner/drinkpinner, enkel design i metall (noe sånt som denne eller denne )</li>
        <li>Astra Superior Platinum barberblader</li>
        <li>Parfyme: bon parfumeur 901</li>
        <li>Parfyme: bon parfumeur 003</li>
        <li>Gulost fra Nederland (typ Gouda o.l.)</li>
        <li>Glass fra Kosta Boda-serien som heter Chateau (finnes flere steder, under er det de heter på kostaboda.se, i rekkefølge av hvilken eg ønsker mest :P )
          <ul>
            <li>Tumbler 38 cL</li>
            <li>Champ 21 cL (15cL)</li>
            <li>Coup 35 cL</li>
          </ul>
        </li>
        <li>
        Tullete gaver:
          <ul>
            <li>3D penn</li>
            <li>Novium Hoverpen 2.0</li>
            <li>Samurai Sword Umbrella</li>
        </ul>
        </li>
        <li>Noe annet som du tror jeg muligens kanskje synes er gøy/morsomt/hyggelig å få i gave</li>
      </ul>
    </>
  );
}

export default Rutger;
