function Marini() {
  return (
    <>
      <h2>Marini</h2>
      <ul>
        <li>e bok; den fjerde kysten , av Virginia Baily</li>
        <li>Brettspill: Sagrada</li>
        <li>Bakeform for 12 muffins</li>
        <li>
          Knebord, for eksempel fra bosign hos nordic nest. Slik at du kan sitte
          og spise med middags tallerken på den. Farge er ikke viktig.
        </li>
        <li>E. Bok, blyantskissen</li>
        <li>E. Bok, Alt det vi ikke kan si</li>
        <li>
          En flaske rødvin er alltid velkommen, VIKTIG, med minst mulig
          garvestoffer, til sammenligning drikker vi til vanlig Ruby Zin.{" "}
        </li>
        <li>Hos Kid, Local Chef 2x 4 pk drikkebrikke svart - ø 10 cm</li>
        <li>Silikonkjevle</li>
      </ul>
    </>
  );
}

export default Marini;
