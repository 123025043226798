import "./Christmas.css";
import video from "./Christmas.mp4";
import { useState } from 'react';
import Erik from './Erik';
import Adelina from "./Adelina";
import Amalie from "./Amalie";
import Jonas from "./Jonas";
import Kristine from "./Kristine";
import Marini from "./Marini";
import Rob from "./Rob";
import Rutger from "./Rutger";
import Thomas from "./Thomas";
import { ChristmasCard } from "../components/ChristmasCard";

function Christmas() {
    const [activeWishlist, setActiveWishlist] = useState("");
    const names = ["Adelina", "Amalie", "Erik", "Jonas", "Kristine", "Marini", "Rob", "Rutger", "Thomas"];

  return (
    <>
      <video className="videoTag" autoPlay loop muted  onClick={() => { setActiveWishlist("") }}>
        <source src={video} type="video/mp4" />
      </video>
      <div className="christmas-body">
        <div className="christmas-card-container">
          {
            (activeWishlist === "Adelina" && (<ChristmasCard element={<Adelina />} onClick={() => {setActiveWishlist("")}}/>)) ||
            (activeWishlist === "Amalie" && (<ChristmasCard element={<Amalie />} onClick={() => {setActiveWishlist("")}}/>)) ||
            (activeWishlist === "Erik" && (<ChristmasCard element={<Erik />} onClick={() => {setActiveWishlist("")}}/>)) ||
            (activeWishlist === "Jonas" && (<ChristmasCard element={<Jonas />} onClick={() => {setActiveWishlist("")}}/>)) ||
            (activeWishlist === "Kristine" && (<ChristmasCard element={<Kristine />} onClick={() => {setActiveWishlist("")}}/>)) ||
            (activeWishlist === "Marini" && (<ChristmasCard element={<Marini />} onClick={() => {setActiveWishlist("")}}/>)) ||
            (activeWishlist === "Rob" && (<ChristmasCard element={<Rob />} onClick={() => {setActiveWishlist("")}}/>)) ||
            (activeWishlist === "Rutger" && (<ChristmasCard element={<Rutger />} onClick={() => {setActiveWishlist("")}}/>)) ||
            (activeWishlist === "Thomas" && (<ChristmasCard element={<Thomas />} onClick={() => {setActiveWishlist("")}}/>))
          }
        </div>
      
        <div className="christmas-navbar">
          {names.map((name) =>
            <button className="wishlist-btn" onClick={() => setActiveWishlist(name)}>{name}</button>
          )}
        </div>
      </div>
    </>
  );
}

export default Christmas;
