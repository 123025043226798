function Jonas() {
    return(
        <>
            <h2>Jonas</h2>
            <ul>
                <li>Mahrez fotballdrakt</li>
                <li>Minecraft lego</li>
                <li>Messi forballdrakt</li>
                <li>Fotball-lego</li>
                <li>Snowracer</li>
                <li>Tøfler</li>
                <li>Plusplus</li>
                <li>Foosball</li>
                <li>Akematte</li>
                <li>Radiostyrt bil</li>
                <li>Lavalampe</li>
            </ul>
        </>
    )
}

export default Jonas;