function Adelina() {
    return(
        <>
            <h2>Adelina</h2>
            <ul>
                <li>Tøfler</li>
                <li>Brødrister</li>
                <li><a href="https://philippastudios.no/products/ava-hoops-small-forgylt?pr_prod_strat=copurchase&pr_rec_id=74ce2ebf1&pr_rec_pid=5467016921241&pr_ref_pid=7368495038617&pr_seq=uniform" target="_blank" rel="noreferrer">Øredobber</a></li>
                <li>Gavekort på fargerike</li>
                <li>Tursekk</li>
                <li>Treningstøy M</li>
                <li>Stekepanne</li>
                <li>Desilitermål</li>
                <li>JBL Tune 230NC TWS</li>
            </ul>
        </>
    )
}

export default Adelina;
