function Erik() {
    return(
        <>
        <h2>Erik</h2>
            <ul>
                <li>Treningsbukse M</li>
                <li>Trenings t-skjorter L</li>
                <li>Biffbestikk</li>
                <li>Sokker</li>
                <li>Tøfler (str 42)</li>
                <li>Hettegenser L</li>
                <li>Grillspyd</li>
                <li>Slækkebukse</li>
                <li>Knevarmere, f.eks <a target="_blank" href="https://www.gymgrossisten.no/knee-sleeves-7mm/KS001-001R.html">disse</a></li>
                <li>Wrist straps stiff f.eks <a target="_blank" href="https://www.gymgrossisten.no/wrist-wraps-stiff/WW001-002R.html">disse</a></li>
            </ul>
        </>
    )
}

export default Erik;