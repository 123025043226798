import { AiOutlineClose } from "react-icons/ai";
import { IconContext } from "react-icons";
import "./ChristmasCard.css";

export const ChristmasCard = ({ element, onClick }) => {
  return (
    <div className="wishlist-card">
      <div className="wishlist-header">
        <IconContext.Provider value={{ color: "#F8B229", size: "30px", className: "cross" }}>
          <div>
            <AiOutlineClose onClick={onClick} />
          </div>
        </IconContext.Provider>
      </div>
      <div className="wishlist-body">
        {element}
      </div>
    </div>
  );
};
