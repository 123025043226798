function Amalie() {
    return(
        <>
            <h2>Amalie</h2>
            <ul>
                <li>Havfrue barbie med hår i mange farger</li>
                <li>Andre barbieting</li>
                <li>LOL dukke</li>
                <li>Havfrueting</li>
                <li>Enhjørningting</li>
                <li>Plusplus</li>
                <li>Enhjørningtøfler</li>
                <li>Radiostyrt bil</li>
                <li>Lavalampe</li>
            </ul>
        </>
    )
}

export default Amalie;