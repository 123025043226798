function Thomas() {
    return(
        <>
            <h2>Thomas</h2>
            <ul>
                <li>Spellbook cards Dungeons and Dragons (UNDER ER DE EG ALLEREDE HAR)</li>
                <ul>
                    <li><a href="https://www.outland.no/cleric-spellbook-cards-version-3-9780786966516" target="_blank">Cleric Spellbook Cards V3</a></li>
                    <li><a href="https://www.outland.no/paladin-spellbook-cards-version-3-9780786966493" target="_blank">Paladin Spellbook Cards V3</a></li>
                    <li><a href="https://www.outland.no/druid-spellbook-cards-version-3" target="_blank">Druid Spellbook Cards V3</a></li>
                    <li><a href="https://www.outland.no/ranger-spellbook-cards-version-3" target="_blank">Ranger Spellbook Cards V3</a></li>
                    <li><a href="https://www.outland.no/xanathar-s-guide-spellbook-cards-9780786966530" target="_blank">Xanathar's Guide Spellbook Cards</a></li>
                </ul>
                <li>Skjeggolje, shaper, shampo, balsam, grooming spray, hair clay fra Beard Monkey (alle lukter er greie, bare ikke Minty & Raspberry)</li>
                <li>Kaffebønner</li>
                <li>Artige sokker</li>
                <li>Villainous expansions</li>
                <ul>
                    <li>Marvel Villainous: Infinite Power</li>
                    <li>Marvel Villainous: Mischief and Malice</li>
                    <li>Disney Villainous: Despicable Plots</li>
                    <li>Disney Villainous: Bigger and Badder</li>
                </ul>
                <li>Lange skeier</li>
                <li>Andre brettspill du tror eg kan like (muligens lurt å sjekke med Kristine om vi allerede har det)</li>
            </ul>
        </>
    )
}

export default Thomas;