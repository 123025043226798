function Kristine() {
    return(
        <>
            <h2>Kristine</h2>
            <ul>
                <li>Tøfler str 38</li>
                <li>Ullsokker</li>
                <li>Ahava hudpleieprodukt, for tørr hud</li>
                <li><a target="_blank" href="https://www.jernia.no/kj%C3%B8kken/matlaging/kj%C3%B8kkenmaskin/kj%C3%B8kkenmaskin-tilbeh%C3%B8r/kitchen-aid-glassbolle-til-kj%C3%B8kkenmaskin-artisan-klar-4%2C8l/p/11769307?queryID=905ecb4448c371603b93a1463c34d88b">Kitchenaid glassbolle</a></li>
                <li>Serveringsskje <a taget="_blank" href="https://www.kitchn.no/borddekking/bestikk/serveringsbestikk/eva-solo-green-tool-serveringsskje-294-cm/">eksmepel</a> eller lignende</li>
                <li>Kakefat (max 30cm diameter; ingen eller lav stett)</li>
                <li>Maja Lunde, pocketbøker</li>
                <ul>
                    <li>Bienes historie</li>
                    <li>Blå</li>
                    <li>Przewalskis hest</li>
                    <li>Drømmen om et tre</li>
                </ul>
            </ul>
        </>
    )
}

export default Kristine;