function Rob() {
    return(
        <>
            <h2>Rob</h2>
            <ul>
                <li>"Noe veldig nyttig"</li>
                <ul>
                    <li>Ikke gi no til meg men til folk som virkelig trenger noe.</li>
                </ul>
                <li>"Noe nyttig"</li>
                <ul>
                    <li>Minibrenner, Biltema, Art.nr. 17-680</li>
                    <li>Gassflaske til denne</li>
                </ul>
                <li>"Noe morsomt & nyttig"</li>
                <ul>
                    <li>Knog Oi Classic Small ringeklokka, rød eller blå. «Small» = egnet til 22,2mm diameter.</li>
                    <li>https://bikable.no/tilbehor/sidespeil/zefal-sidespeil-barends</li>
                    <li> pysj, https://www2.hm.com/no_no/productpage.0683124068.htmln størrelse L</li>

                </ul>
                <li>"Noe å lese"</li>
                <ul>
                    <li>Min skam. Nadia Ansar. e-bok</li>
                </ul>
                <li>"Noe å leke"</li>
                <ul>
                    <li>Brettspill: Sagrada, ikke selve spillet, men utvidelsen til 5 & 6 personer.</li>
                    <li>Kortspill: Uno 😜😜 gjerne 2 sett</li>
                </ul>
                <li>"Noe til å ha drikke i"</li>
                <ul>
                    <li>Circle K Kaffe-avtale-koppen 2024, ja takk til en fargerik kopp.</li>
                </ul>
            </ul>
        </>
    )
}

export default Rob;